import {Button, Col, Form} from "react-bootstrap";
import Constant from "./Constant";
import {useContext, useState} from "react";
import BrandingContext from "./BrandingContext";

export default function UpdateInput({onSubmit, onChange, disabled}) {
  const [licenceInfo, setLicenceInfo] = useState(null);
  const branding = useContext(BrandingContext)
  const newValue = evt => evt.target.value === "" ? null : evt.target.value

  return (
    <>
      <Form onSubmit={evt => {onSubmit(licenceInfo); evt.preventDefault()}} >

        <Form.Group controlId="licenceData">
          <Form.Row>
            <Form.Label column lg={Constant.leftColumnSize}>Device information</Form.Label>
            <Col>
              <Form.Control as="textarea"
                            className={"text-monospace update"}
                            rows={14}
                            required
                            value={licenceInfo || ""}
                            placeholder={"Device information"}
                            onChange={evt => {
                              setLicenceInfo(newValue(evt));
                              onChange();
                            }}
              />

              <Form.Text className="text-muted">
                Output generated by {branding.productName} Offline Update.
              </Form.Text>
            </Col>
          </Form.Row>
        </Form.Group>

        <div className={"text-center"}>
          <Button variant="primary" type={"submit"} disabled={disabled}>
            Submit{disabled && "ting..."}
          </Button>
        </div>

      </Form>
    </>
  )
}