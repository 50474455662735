import {Alert} from "react-bootstrap";

export default function ErrorMessage({message}) {
  if (message) {
    return (<Alert variant={"danger"}>
      {message}
    </Alert>)
  } else {
    return null;
  }
}
