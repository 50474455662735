import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faCircle, faGlobe} from "@fortawesome/pro-light-svg-icons";
import {Alert, Button, Table} from "react-bootstrap";
import Util from "./util";
import {useContext, useState} from "react";
import BrandingContext from "./BrandingContext";

export default function UpdaterList({updateInfo, download, currentVersion, licenceInfo}) {
    const [selectedVersion, setSelectedVersion] = useState(null);

    const branding = useContext(BrandingContext);

    if (!updateInfo || !updateInfo.updaters || !updateInfo.licence) {
        return null;
    }

    const kafka_schema_mismatch = licenceInfo.software.kafka_schema_version !== selectedVersion?.kafkaSchemaVersion;

    const setSelectedVersionItem = i => version => {
        setSelectedVersion(version);
    }

    const LicenceInfo = () => {
        return (
          <>
              <h3>Licence information</h3>
              <Table striped bordered hover size="sm">
                  <tbody>
                  {
                      Object.keys(updateInfo.licence).map(key => {
                          return (
                            <tr key={key}>
                                <td>{Util.formatKeys(key)}</td>
                                <td>{updateInfo.licence[key]}</td>
                            </tr>
                          )
                      })
                  }
                  </tbody>
              </Table>
          </>
        )
    }


    if (!updateInfo.updaters.length) {
        return (
          <div className={"withVMargin"}>
              <LicenceInfo/>
              <h3>Updates</h3>
              <p>Current version: {currentVersion}</p>
              <p>There are no available updaters.</p>
          </div>
        )
    }

    return (
      <div className={"withVMargin"}>
          <LicenceInfo/>

          <h3>Updates</h3>

          <p>Current version: {currentVersion}</p>

          <p>Available updates:</p>

          <Table striped bordered hover id={"updateList"}>
              <thead>
              <tr>
                  <th>Select version</th>
                  <th>Version number</th>
                  <th>Release date</th>
                  <th>File size (MB)</th>
                  <th>Change log</th>
                  <th>Licence restriction</th>
              </tr>
              </thead>
              <tbody>
              {updateInfo.updaters.map((elem, i) => <AvailableVersion version={elem}
                                                                      key={i}
                                                                      selectedVersion={selectedVersion}
                                                                      selectVersion={setSelectedVersionItem(i)}
                                                                      isAvailable={Util.isVersionAvailable(updateInfo.licence.maxProductVersion, elem.targetVersion)}
              />)}
              </tbody>
          </Table>


        {selectedVersion && (kafka_schema_mismatch || selectedVersion.messages?.length) && (
          <Alert variant={"warning"}>
            <b>After installing this update, some additional configuration may be required.</b>
            {kafka_schema_mismatch && <p><br/>
              All events and their videos will be permanently lost.
              If you are using a third party software that connects to {branding.productName}, it may need to be updated as well.
            </p>}
            {
              selectedVersion.messages?.length ? <>
                <p><b>Additional details for this update:</b></p>
                <ul className={"installMessages"}>
                  {selectedVersion.messages.map((el,i) => <li key={i}>
                    <span className={"installMessage"}>{el}</span>
                  </li>)}
                </ul>
              </> : null}
          </Alert>)
        }

          <div className={"text-center"}>
              <Button
                disabled={!selectedVersion}
                onClick={() => download(selectedVersion)}
              >
                  Get download link
              </Button>
          </div>

      </div>
    )
}

function AvailableVersion({version, selectedVersion, selectVersion, isAvailable}) {
    const isSelected = selectedVersion?.targetVersion === version.targetVersion &&
      selectedVersion.sourceVersion === version.sourceVersion;

    const branding = useContext(BrandingContext);

    const versionSlug = version.targetVersion.replaceAll(".", "")
    return (
      <>
          <tr className={isSelected ? "selected" : ""}>

              <td onClick={() => {
                  if (isAvailable) {
                      selectVersion(version);
                  }
              }}
                  className={isAvailable ? "cursorPointer" : ""}>
                  <FontAwesomeIcon icon={ isSelected ? faCheckCircle : faCircle}
                                   className={isAvailable ? "available" : "unavailable"}
                  />
              </td>

              <td>{version.targetVersion}</td>
              <td>{version.releaseDate}</td>
              <td>{Math.round(version.downloadSize / 1_000_000)}</td>
            {
              branding.releaseNotesUrl &&
              <td>
                  <a href={`${branding.releaseNotesUrl}${versionSlug}`}
                                                 rel="noreferrer"
                                                 target={"_blank"}>
                    <FontAwesomeIcon icon={faGlobe}/>
                  </a>
              </td>
            }
              <td>{isAvailable ? "AVAILABLE" : "NO LICENCE"}</td>
          </tr>
      </>
    )
}
