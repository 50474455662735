import ActivationInput from "./ActivationInput";
import {useEffect, useState} from "react";
import ULPSApiClient from "./ULPSApiClient";
import Constant from "./Constant";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";

export default function Activation() {
    const [errorMessage, setErrorMessage] = useState(null);
    const [dataJson, setDataJson] = useState(null);

    useEffect(() => {
        downloadJsonFile();
    }, [dataJson])

    const downloadJsonFile = () => {
        if (!!dataJson) {
            try {
                setErrorMessage(null);
                const element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(dataJson)));
                element.setAttribute('download', Constant.fileName);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            } catch (err) {
                console.error(err)
                setErrorMessage(err.message);
            }
        }
    }

    const onSubmit = async (activationKey, licenceData, customerName, contactName, contactEmail) => {
        clearMessage();

        if (!contactName || !contactEmail || !customerName) {
            if (!window.confirm("Customer name, contact name or e-mail is empty. Are you sure you want to submit anyway?")) {
                return;
            }
        }

        try {
            const dataJson = await ULPSApiClient.postKeys(activationKey, licenceData, customerName, contactName, contactEmail);
            setDataJson(dataJson);
        } catch (err) {
            setErrorMessage(err.message);
        }
    }

    const clearMessage = () => {
        setErrorMessage(null);
        setDataJson(null);
    }

    return (
      <>
          <h2>Licence Activation</h2>
          <ActivationInput onSubmit={onSubmit} onChange={clearMessage} />
          <ErrorMessage message={errorMessage} />
          <SuccessMessage messageData={dataJson} hasZipFile={!!dataJson} download={downloadJsonFile} />
      </>
    );
}
