import {Button, Col, Container, Row} from "react-bootstrap";

import Activation from "./Activation";
import Update from "./Update";
import {useContext, useState} from "react";
import BrandingContext from "./BrandingContext";

const pageRouter = {HOME: "HOME", ACTIVATION: "ACTIVATION", UPDATE: "UPDATE"};

export default function App() {
    const [page, setPage] = useState(pageRouter.HOME);
    const branding = useContext(BrandingContext)
    document.title = branding.lpsAppName
    const favicon = document.getElementById('favicon')
    if (favicon) {
      favicon.href = branding.faviconPath;
    }
    return <div className="App">
        <Container>
            {page !== pageRouter.HOME &&
              <Button size={"large"} variant={"outline-secondary"} onClick={() => setPage(pageRouter.HOME)}
                      id="backButton">Go back</Button>
            }
            <h1>{branding.lpsAppName}</h1>

            {page === pageRouter.ACTIVATION && <Activation/>}
            {page === pageRouter.UPDATE && <Update/>}
            {page === pageRouter.HOME && <>

                <br/>
                <Row>
                    <Col>
                        <Button onClick={() => setPage(pageRouter.ACTIVATION)} size={"large"} block>
                            Licence Activation
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => setPage(pageRouter.UPDATE)} size={"large"} block>
                            Version Update
                        </Button>
                    </Col>
                </Row>
            </>
            }
        </Container>
    </div>

}
