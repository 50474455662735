import {Alert, Button} from "react-bootstrap";

export default function SuccessMessage({messageData, hasZipFile, download}) {
  const valueWithUTCIfNeeded = (time) => {
    if (time === "infinite") {
      return time;
    } else {
      return `${time} (UTC)`;
    }
  }

  if (messageData && hasZipFile) {
    return (<Alert variant={"success"}>
      <p><strong>Successful licence generation</strong></p>
      <p>
        Licence valid until: {valueWithUTCIfNeeded(messageData.licenceValidUntil)}<br/>
        Support valid until: {valueWithUTCIfNeeded(messageData.supportValidUntil)}<br/>
        Maximum product version: {messageData.maxProductVersion}<br/>
        Maximum number of streams: {messageData.maxStreams}
      </p>
      <Button variant="primary" type={"submit"} onClick={download}>
        Download licence file
      </Button>
    </Alert>)
  } else {
    return null;
  }
}
