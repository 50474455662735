import Constant from "./Constant";
import axios from "axios";

class ULPSApiClient {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  async postKeys(activationKey, licenceData, customerName, contactName, contactEmail) {
    try {
      const data = {activationKey, licenceData};
      if (contactName) {
        data.contactName = contactName;
      }
      if (contactEmail) {
        data.contactEmail = contactEmail;
      }
      if (customerName) {
        data.customerName = customerName;
      }
      const response = await axios.post(`${this.apiUrl}/activateLicence2`, data);
      return response.data;
    } catch (err) {
      ULPSApiClient.handleError(err);
    }
  }

  async checkForUpdates(data) {
    // return {
    //   licence: {
    //     maxProductVersion: "2.0.0"
    //   },
    //   "updaters": [{
    //     "target_version": "1.1.5",
    //     "source_version": "1.1.0",
    //     "release_date": "2021-02-23 15:43:14",
    //     "size": 10497975
    //   }]
    // }

    if (!data.activationKey || !data.licenceData || !data.software) {
      throw new Error("Invalid licence data.");
    }

    try {
      const response = await axios.post(`${this.apiUrl}/checkForUpdates`, data);
      return response.data;
    } catch (err) {
      ULPSApiClient.handleError(err);
    }
  }

  async download(sourceVersion, targetVersion, data) {

    // return {
    //   "target_version": "1.1.5",
    //   "source_version": "1.1.0",
    //   "release_date": "2021-02-23 15:43:14",
    //   "size": 10497975,
    //   "url": "https://fvfodjdpojfdpoléfkdlékfdőkd"
    // }

    if (!data.activationKey || !data.licenceData || !data.software) {
      throw new Error("Invalid licence data.");
    }
    if (!sourceVersion || !targetVersion) {
      throw new Error("There is no selected version.")
    }

    try {
      const response = await axios.post(`${this.apiUrl}/download/${targetVersion}/${sourceVersion}`, data);
      return response.data;
    } catch (err) {
      ULPSApiClient.handleError(err);
    }
  }

  static handleError(error) {
    console.error(error);
    let message = error && error.response && error.response.data ? error.response.data : error.message;
    const TRIM_HTTP_ERROR_CODE = /^\[[0-9]+\]\ /
    message = message.replace(TRIM_HTTP_ERROR_CODE, '');
    throw new Error(message);
  }
}

const clientInstance = new ULPSApiClient(Constant.apiUrl);
export default clientInstance;
