import React from "react";

const ultinous = {
  additionalSupportInfo: [
    "Provide easier access to product support (support@ultinous.com)"
  ],
  companyName: "Ultinous",
  eulaUrl: "https://docs.ultinous.com/legal",
  faviconPath: "/favicon.png",
  lpsAppName: "Ultinous Licence Provisioning Service",
  productName: "Ultinous AI Suite",
  releaseNotesUrl: "https://docs.ultinous.com/ualarm/docs/u-alarm/release_notes_u_alarm#"
}

const synectics = {
  additionalSupportInfo: [],
  companyName: "Synectics",
  eulaUrl: "http://legal.synx.com/eula",
  faviconPath: "/synectics/favicon.png",
  lpsAppName: "Synectics Licence Provisioning Service",
  productName: "Synergy DETECT",
  releaseNotesUrl: null
}
const brandings = {
  ultinous,
  synectics
}

const actualBranding = brandings[process.env.REACT_APP_BRANDING || "ultinous"];

export default React.createContext(actualBranding);