const Util = {};

Util.isVersionAvailable = (maxProductVersion, currentVersion) => {
  const current = currentVersion.split(".");
  const maximum = maxProductVersion.split(".");

  for (let i = 0; i < 3; i++) {
    if (maximum[i] === "infinite") {
      return true;
    }
    if (Number(current[i]) < Number(maximum[i])) {
      return true; /// 2.0.0 vs 1.1.5 TODO fix in dlservice
    }
    if (Number(current[i]) > Number(maximum[i])) {
      return false;
    }
  }
  return true;
}

Util.formatKeys = key => {
  const words = key.replace( /([A-Z])/g, " $1" );
  const leadingUpperCase = words.charAt(0).toUpperCase() + words.slice(1);
  return leadingUpperCase
}

export default Util;