import {useContext, useState} from "react";
import {Button, Col, Form} from "react-bootstrap";
import Constant from "./Constant";
import BrandingContext from "./BrandingContext";

export default function ActivationInput({onSubmit, onChange}) {
  const [activationKey, setActivationKey] = useState(null);
  const [licenceData, setLicenceData] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);

  const branding = useContext(BrandingContext)

  const LICENCE_DATA_LINE_NUMBER = 4;

  const newValue = evt => evt.target.value === "" ? null : evt.target.value

  return (
    <Form onSubmit={(evt) => {
      onSubmit(activationKey, licenceData, customerName, contactName, contactEmail);
      evt.preventDefault();
    }}>
      <Form.Group controlId="activationKey">
        <Form.Row>
          <Form.Label column lg={Constant.leftColumnSize}>Activation key</Form.Label>
          <Col>
            <Form.Control type="text"
                          placeholder="Activation key"
                          required
                          value={activationKey || ""}
                          onChange={evt => {
                            setActivationKey(newValue(evt));
                            onChange();
                          }}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="licenceData">
        <Form.Row>
          <Form.Label column lg={Constant.leftColumnSize}>Licence data</Form.Label>
          <Col>
            <Form.Control as="textarea"
                          className={"text-monospace"}
                          rows={LICENCE_DATA_LINE_NUMBER}
                          required
                          value={licenceData || ""}
                          placeholder={"Licence data"}
                          onChange={evt => {
                            setLicenceData(newValue(evt));
                            onChange();
                          }}
            />

          <Form.Text className="text-muted">
            Output generated by {branding.productName}.
          </Form.Text>
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="customerName">
        <Form.Row>
          <Form.Label column lg={Constant.leftColumnSize}>Customer name</Form.Label>
          <Col>
            <Form.Control type="text"
                          placeholder="Customer name"
                          value={customerName || ""}
                          onChange={evt => {
                            setCustomerName(newValue(evt));
                            onChange();
                          }}
            />
            <Form.Text className="text-muted">
              The customer name will be added to the generated licence text file.
            </Form.Text>
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="contactName">
        <Form.Row>
          <Form.Label column lg={Constant.leftColumnSize}>Contact name</Form.Label>
          <Col>
            <Form.Control type="text"
                          value={contactName || ""}
                          placeholder={"Contact name (if available)"}
                          onChange={evt => {
                            setContactName(newValue(evt));
                            onChange();
                          }}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="contactEmail">
        <Form.Row>
          <Form.Label column lg={Constant.leftColumnSize}>Contact e-mail</Form.Label>
          <Col>
            <Form.Control type="email"
                          placeholder="Contact e-mail"
                          value={contactEmail || ""}
                          onChange={evt => {
                            setContactEmail(newValue(evt));
                            onChange();
                          }}
            />
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group controlId="acceptTerms">
        <Form.Check type="checkbox"
                    required
                    label={<Form.Label>I accept the <a href={branding.eulaUrl} target={"_blank"} rel="noreferrer">End User Licence Agreement (EULA)</a> of {branding.productName}</Form.Label>}
        />
      </Form.Group>

      <div className={"aboutContactInfo"}>
      <p>By providing the contact information above we will be able to</p>
      <ul>
        <li>Notify customers on important updates</li>
        <li>Notify customers on possible security and vulnerability related issues</li>
        {branding.additionalSupportInfo.map((el, i) => <li key={`additionalSupportInfo_${i}`}>{el}</li>)}
      </ul>
      </div>

      <div className={"text-center"}>
        <Button variant="primary" type={"submit"}>
          Submit
        </Button>
      </div>

    </Form>
  );
}
