import UpdateInput from "./UpdateInput";
import ErrorMessage from "./ErrorMessage";
import {useContext, useState} from "react";
import ULPSApiClient from "./ULPSApiClient";
import UpdaterList from "./UpdaterList";
import {Alert} from "react-bootstrap";
import BrandingContext from "./BrandingContext";

export default function Update() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [updateInfo, setUpdateInfo] = useState(null);
  const [downloadInfo, setDownloadInfo] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [licenceInfo, setLicenceInfo] = useState(null);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const branding = useContext(BrandingContext);

  const onSubmit = async licenceInfoText => {
    setDisabledSubmit(true);
    try {
      setErrorMessage(null);
      const licenceInfo = JSON.parse(licenceInfoText);

      if (!licenceInfo.activationKey)
        throw new Error("Invalid device information: Missing 'activationKey'.");
      if (!licenceInfo.licenceData)
        throw new Error("Invalid device information: Missing 'licenceData'.");
      if (!licenceInfo.software)
        throw new Error("Invalid device information: Missing 'software'.");
      if (!licenceInfo.software.version)
        throw new Error("Invalid device information: Missing 'software'.'version'.");

      setLicenceInfo(licenceInfo);
      setCurrentVersion(licenceInfo.software.version);
      ULPSApiClient.checkForUpdates(licenceInfo).then( info => {
        setUpdateInfo(info);
        setDisabledSubmit(false);
      }).catch(err => {
        setErrorMessage(err.message);
        setDisabledSubmit(false);
      });
    } catch (err) {
      setErrorMessage(err.message);
      setDisabledSubmit(false);
    }
  }

  const clear = () => {
    setErrorMessage(null);
    setUpdateInfo(null);
    setDownloadInfo(null);
  }

  const download = async selectedVersion => {
    setErrorMessage(null);
    try {
      const download = await ULPSApiClient.download(selectedVersion.sourceVersion, selectedVersion.targetVersion, licenceInfo);
      setDownloadInfo(download)
    } catch (err) {
      setErrorMessage(err.message);
    }
  }

  return (
    <>
      <h2>Version Update</h2>
      <p>This page displays available firmware updates for {branding.productName}. If your device is not connected to internet,
      you can download the selected version. The download link is valid for 3 days.</p>
      <p>List of the downloadable updates depend on the licence restrictions.</p>
      <p>For device information go to {branding.productName} Update page, and select Offline update.</p>
      <UpdateInput onSubmit={onSubmit} onChange={clear} disabled={disabledSubmit}/>

      <UpdaterList updateInfo={updateInfo} licenceInfo={licenceInfo} download={download} currentVersion={currentVersion} />
      <ErrorMessage message={errorMessage}/>
      {downloadInfo &&
      <Alert variant={"success"}>
        Target version: {downloadInfo.targetVersion}<br/>
        Release date: {downloadInfo.releaseDate}<br/>
        Size: {Math.round(downloadInfo.downloadSize / 1_000_000)} MB<br/>
        Url (valid for 3 days): <a href={downloadInfo.url} target={"_blank"} rel={"noreferrer"}>{downloadInfo.url}</a>
      </Alert>
      }
    </>
  );
}
